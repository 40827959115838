import React, { useEffect, useState } from 'react';

const SvgComponent = ({ parts, partPoint, partState, partName }) => {
  // 각 path의 상태를 관리
  const [item, setItem] = useState('');

  const getPartColor = (value) => {
    switch (value) {
      case '1':
        return 'transparent';
      case '2':
        return '#D32F2F'; // 교환 (X)
      case '3':
        return '#2296F3'; // 판금 (W)
      default:
        // 교환, 판금 외에는 표시하지 않음
        return 'transparent'; // 기본 색상
    }
  };

  useEffect(() => {
    const item = parts.find((item) => item.partPoint === partPoint);
    setItem(item);
  }, [partPoint, parts]);

  const BOTTOM = '0 0 655 1200';
  const TOP = '0 0 655 1000';
  const RIGHT = '25 155 650 1000';
  const LEFT = '-10 155 650 1000';

  function getViewBox(partName) {
    switch (partName) {
      case 'PARTS_BOTTOM':
        return BOTTOM;
      case 'PARTS_TOP':
        return TOP;
      case 'PARTS_RIGHT':
        return RIGHT;
      case 'PARTS_LEFT':
        return LEFT;
      default:
        return '0 0 655 1200';
    }
  }

  return (
    <svg
      viewBox={getViewBox(partName)}
      width="100%"
      height="100%"
      opacity={0.6}
    >
      <g
        fill={getPartColor(partState)}
        dangerouslySetInnerHTML={{ __html: item?.element }}
      />
    </svg>
  );
};

export default SvgComponent;
