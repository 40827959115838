import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { BlockUI } from 'primereact/blockui';
import { Menubar } from 'primereact/menubar';
import PerformanceCheckReport from '../../components/PerformanceCheck/PerformanceCheckReport';
import { ServiceProvider } from '../../services';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { Repair } from '../../services/PerformanceCheckService';

const performanceCheckService = ServiceProvider.performanceCheck;

const formatTSRepairHistory = (data) => {
  return data.map((work) => {
    // 중복 제거를 위해 Set 사용
    const uniqueItems = new Set();

    // 모든 작업 항목 수집
    work.workItems.forEach((item) => {
      uniqueItems.add(item.partDeatilName);
    });

    // 시작일 포맷팅
    const inDay = dayjs(work.workRequestDate).format('YYYY-MM-DD');
    const items = Array.from(uniqueItems).sort();

    return {
      inDay,
      items,
    };
  });
};

const formatNiceDNRRepairHistory = (data) => {
  // 시작날짜별로 아이템을 그룹화하는 객체 생성
  const groupedByDate = data.reduce((acc, repair) => {
    if (!acc[repair.inDay]) {
      acc[repair.inDay] = [];
    }
    acc[repair.inDay].push(repair.workName);

    return acc;
  }, {});

  // 그룹화된 데이터를 원하는 형식의 배열로 변환
  return Object.entries(groupedByDate).map(([inDay, items]) => {
    return {
      inDay,
      items,
    };
  });
};

const getNiceDNRData = async ({ lpns, vin, vinRemain, regdate }) => {
  if (!lpns || !vin || !regdate) {
    return null;
  }

  try {
    const combinedVIN = vin + (vinRemain || '');
    const data = await Repair.getCenterCommercial(lpns, combinedVIN, regdate);
    const result = formatNiceDNRRepairHistory(data);
    return result;
  } catch (error) {
    console.error('NICEDNR 카센터 조회 실패:', error);

    // 400 에러일 경우 데이터 없는 경우라 에러 띄우지 않음
    if (error.response?.status === 400) {
      return [];
    }

    window.cerp.dialog.error(
      'NICEDNR 카센터 정비이력 조회 실패',
      `[${error.response?.data?.error?.code}] ${error.response?.data?.error?.message}`
    );
    return [];
  }
};

const PerformanceCheckViewer = ({
  match: {
    params: { statementNumber, dataId },
  },
}) => {
  const reportRef = useRef(null);
  const reportCaptureRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [checkData, setCheckData] = useState(null);
  const [addData, setAddData] = useState([]);
  const [workDetails, setWorkDetails] = useState([]);
  const [repairDetails, setRepairDetails] = useState([]);
  const [checkPhotos, setCheckPhotos] = useState(null);
  const [data, setData] = useState({
    paymentInformation: null,
    association: null,
    inspector: null,
    vehicleInformation: null,
    customerCompany: null,
  });

  const [errorData, setErrorData] = useState(null);
  const [reportCaptureData, setReportCaptureData] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => {
      // const paperWrapper = reportRef.current.querySelectorAll('.paper_wrapper');

      // console.log(paperWrapper);

      // if (paperWrapper.length > 0) {
      //   paperWrapper.forEach((wrapper) => {
      //     wrapper.style.width = '210mm';
      //     wrapper.style.height = '297mm';
      //     wrapper.style.minHeight = '297mm';
      //     wrapper.style.border = 'initial';
      //     wrapper.style.borderRadius = 'initial';
      //     wrapper.style.boxShadow = 'initial';
      //     wrapper.style.background = 'initial';
      //     wrapper.style.pageBreakAfter = 'initial';
      //   });
      // }

      const papers = reportRef.current.querySelectorAll('.paper');
      if (papers.length === 0) return false;

      // const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
      for (let i = 0; i < papers.length; i++) {
        // 페이지 번호 추가
        const pageNumberEl = document.createElement('div');
        pageNumberEl.innerText = `Page ${i + 1} of ${papers.length}`;
        pageNumberEl.style.position = 'absolute';
        pageNumberEl.style.bottom = '20px';
        pageNumberEl.style.left = '0';
        pageNumberEl.style.width = '100%';
        pageNumberEl.style.textAlign = 'center';
        pageNumberEl.style.fontSize = '10px';
        pageNumberEl.style.zIndex = '99';
        pageNumberEl.classList.add('print-page-number');
        papers[i].appendChild(pageNumberEl);
        // const page = papers[i];
        // page.style.transform = 'rotate(90deg)';
        // page.style.width = `148.5mm`;
        // page.style.height = '210mm';
        // page.style.minHeight = '210mm';
        // page.style.pageBreakAfter = 'avoid';
        // const parts = page.querySelectorAll('.cerp-pp');
        // for (let j = 0; j < parts.length; j++) {
        //   const part = parts[j];
        //   part.style.fontSize = '1rem';
        // }
        // const watermark = buildWatermark(now);
        // page.appendChild(watermark);
      }

      return reportRef.current;
    },
    onBeforePrint: async () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      const watermarks = reportRef.current.querySelectorAll('.cerp-watermark');
      _.map(watermarks, (watermark) => watermark.remove());

      const parts = reportRef.current.querySelectorAll('.cerp-pp');
      _.map(parts, (part) => (part.style.fontSize = '1.25rem'));

      // 프린트 후 페이지 번호 제거
      const pageNumbers = reportRef.current.querySelectorAll('.page-number');
      _.map(pageNumbers, (pageNumber) => pageNumber.remove());

      setLoading(false);
    },
  });

  const handlePDF = async () => {
    setLoading(true);
    const papers = reportRef.current.querySelectorAll('.paper');
    if (papers.length === 0) return false;

    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const pageImages = [];
    const reportPDF = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    for (let i = 0; i < papers.length; i++) {
      const page = papers[i];
      const watermark = buildWatermark(now);
      page.appendChild(watermark);

      const canvas = await html2canvas(page, {
        scale: 2,
        logging: true,
        allowTaint: true,
        useCORS: true,
        imageTimeout: 0,
        backgroundColor: 'transparent',
      });

      const pageImage = canvas.toDataURL('image/png');
      pageImages.push(pageImage);
      page.removeChild(watermark);
    }

    for (let i = 0; i < pageImages.length; i++) {
      reportPDF.addImage(
        pageImages[i],
        'PNG',
        0,
        0,
        210,
        297,
        `PAGE-${i}`,
        'NONE',
        0
      );
      if (i < pageImages.length - 1) reportPDF.addPage();
    }

    reportPDF.save(`성능점검기록부_${statementNumber}.pdf`);
    setLoading(false);
  };

  const buildWatermark = (now) => {
    const watermark = document.createElement('div');
    _.map(
      _.split(
        'cerp-watermark absolute top-50 left-0 right-0 opacity-40 text-gray-500 font-bold p-4rem text-center text-4xl origin-center',
        ' '
      ),
      (cls) => watermark.classList.add(cls)
    );
    watermark.style.transform = 'rotate(-30deg)';
    const text = document.createTextNode(`출력일시 : ${now}`);
    watermark.appendChild(text);

    return watermark;
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      const { data, error } = await performanceCheckService.getData(
        // statementNumber
        dataId
      );

      if (data) {
        const {
          performanceCheckData: {
            hRecheck: hRecheckRaw,
            checkData: checkDataRaw,
            addData: addDataRaw,
            paymentInformation: paymentInformationRaw,
            association: associationRaw,
            inspector: inspectorRaw,
            customerCompany: customerCompanyRaw,
            vehicleInformation: vehicleInformationRaw,
          },
          performanceCheckPhotos,
          workDetails,
        } = data;

        const hRecheck = hRecheckRaw;
        const checkData = JSON.parse(checkDataRaw);
        const addData = JSON.parse(addDataRaw);
        const paymentInformation = JSON.parse(paymentInformationRaw);
        const association = JSON.parse(associationRaw);
        const inspector = JSON.parse(inspectorRaw);
        const customerCompany = JSON.parse(customerCompanyRaw);
        const vehicleInformation = JSON.parse(vehicleInformationRaw);

        const repairDetails = await getNiceDNRData({
          lpns: _.get(vehicleInformation, 'VHRNO'),
          vin: _.get(vehicleInformation, 'VIN'),
          vinRemain: _.get(vehicleInformation, 'vinRemain'),
          regdate: _.get(vehicleInformation, 'FRST_REGIST_DE'),
        });

        const formattedWorkDetail = formatTSRepairHistory(workDetails);
        setRepairDetails(repairDetails);
        setCheckData(checkData);
        setAddData(addData);
        setWorkDetails(formattedWorkDetail);
        setCheckPhotos(performanceCheckPhotos.slice().reverse());
        setData((ps) => ({
          ...ps,
          hRecheck,
          paymentInformation,
          association,
          inspector,
          vehicleInformation,
          customerCompany,
        }));
      }

      if (error) {
        window.cerp.dialog.error(
          '존재하지 않는 성능지번호 입니다.',
          `[${statementNumber}]`
        );
        setErrorData(error);
      }
    })();

    setLoading(false);
  }, [dataId, statementNumber]);

  return (
    <div className="pcr_wrapper">
      <BlockUI
        fullScreen
        blocked={loading}
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
      >
        <Menubar
          className="sticky top-0 z-5"
          model={[
            {
              label: '출력하기',
              icon: 'pi pi-fw pi-print',
              command: handlePrint,
            },
            {
              label: 'PDF 다운로드',
              icon: 'pi pi-fw pi-download',
              command: handlePDF,
            },
          ]}
          start={
            <label className="text-2xl font-semibold mr-2">
              성능점검기록부
            </label>
          }
        />
        <div className="flex flex-row align-items-center justify-content-center py-3">
          {checkData && (
            <PerformanceCheckReport
              ref={reportRef}
              data={checkData}
              addData={addData}
              workDetails={workDetails}
              repairDetails={repairDetails}
              photos={checkPhotos}
              hRecheck={_.get(data, 'hRecheck')}
              payment={_.get(data, 'paymentInformation')}
              association={_.get(data, 'association')}
              inspector={_.get(data, 'inspector')}
              customerCompany={_.get(data, 'customerCompany')}
              vehicleInformation={_.get(data, 'vehicleInformation')}
            />
          )}
        </div>
      </BlockUI>
      {/*{_.map(reportCaptureData, data => (<img src={data} style={{width: '210mm'}} />))}*/}
    </div>
  );
};

export default PerformanceCheckViewer;
