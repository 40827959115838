import _ from 'lodash';

const PC_VALID_DAYS = 60;
const PC_BASE_BLUEPRINT = {
  INTERFACE_ID: {
    title: '인터페이스ID',
    // defaultValue: '',
    origin: '전송결과값',
  },
  CNTC_RESULT_CODE: {
    title: '결과코드',
    // defaultValue: '',
    origin: '전송결과값',
  },
  CNTC_RESULT_DTLS: {
    title: '결과내용',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_GRC_CODE: {
    title: '접수관청코드',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_DE: {
    title: '접수일',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_SN: {
    title: '접수순번',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_UPD_SN: {
    title: '경정순번',
    // defaultValue: '',
    origin: '전송결과값',
  },
  VHMNO: {
    title: '차량관리번호',
    // defaultValue: '',
    origin: '전송결과값',
  },
  CNM: {
    title: '차명',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'CNM',
  },
  VHCTY_ASORT_CODE: {
    title: '차종코드',
    defaultValue: '1',
    valueItems: [
      { label: '승용', value: '1' },
      { label: '승합', value: '2' },
      { label: '화물', value: '3' },
      { label: '특수', value: '4' },
      { label: '이륜', value: '5' },
      { label: '기타', value: '9' },
    ],
  },
  REFORM_AT: {
    title: '개정여부',
    defaultValue: '2',
  },
  VHRNO: {
    title: '차량등록번호',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'VHRNO',
  },
  LATEST_TRVL_DSTNC: {
    title: '최근 주행 거리',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'TRVL_DSTNC',
  },
  TRVL_DSTNC: {
    title: '주행 거리',
    // defaultValue: '',
    // origin: '국토부 자동차기본정보',
    // originKey: 'TRVL_DSTNC',
  },
  TRVL_DSTNC_STTUS_CODE: {
    title: '주행거리 상태코드',
    // defaultValue: '',
    valueItems: [
      { label: '많음', value: '3' },
      { label: '보통', value: '2' },
      { label: '적음', value: '1' },
    ],
  },
  GAUGE_FNCT_AT: {
    title: '계기작동여부',
    defaultValue: '0',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  PRYE: {
    title: '연식',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'PRYE',
  },
  USE_FUEL_CODE: {
    title: '사용연료코드',
    defaultValue: 'a',
    valueItems: [
      { label: '가솔린', value: 'a', name: ['휘발유(무연)'] },
      { label: '디젤', value: 'b', name: ['경유', '디젤'] },
      { label: 'LPG', value: 'c', name: ['LPG', '엘피지'] },
      { label: '전기', value: 'd', name: ['전기'] },
      { label: '수소전기', value: 'e', name: ['수소'] },
      {
        label: '하이브리드',
        value: 'x',
        name: [
          '하이브리드(휘발유+전기)',
          '하이브리드(LPG+전기)',
          '하이브리드(경유+전기)',
        ],
      },
      { label: '기타', value: 'z', name: ['기타'] },
    ],
    origin: '국토부 자동차기본정보',
    originKey: 'USE_FUEL_NM',
  },
  INSPT_VALID_PD_BGNDE: {
    title: '검사유효기간 시작일',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'INSPT_VALID_PD[0]',
  },
  INSPT_VALID_PD_ENDDE: {
    title: '검사유효기간 종료일',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'INSPT_VALID_PD[1]',
  },
  FRST_REGIST_DE: {
    title: '최초등록일',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'FRST_REGIST_DE',
  },
  MTRS_FOM: {
    title: '원동기형식',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'MTRS_FOM_NM',
  },
  GRBX_KND_CODE: {
    title: '변속기종류',
    defaultValue: '',
    valueItems: [
      { label: '자동', value: 'A' },
      { label: '수동', value: 'M' },
      { label: '세미오토', value: 'S' },
      // { label: '무단변속기', value: 'C' },
      { label: '무단', value: 'C' },
      { label: '기타', value: 'X' },
    ],
    origin: '국토부 자동차기본정보',
    originKey: 'GRBX_KND_NM',
  },
  GRBX_KND_DTLS: {
    title: '변속기종류 기타내용',
    defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'GRBX_KND_CODE가 X인 경우',
  },
  VIN: {
    title: '차대번호',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'VIN',
  },
  ASSRNC_TY_SE_CODE: {
    title: '보증유형',
    defaultValue: '2',
    valueItems: [
      { label: '보험사 비보증', value: '1' },
      { label: '보험사 보증', value: '2' },
    ],
  },
  SAMENSS_CNFIRM_CODE: {
    title: '동일성확인차대번호표기',
    defaultValue: '1',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '상이', value: '2' },
      { label: '부식', value: '3' },
      { label: '훼손(오손)', value: '4' },
      { label: '변조(변타)', value: '5' },
      { label: '도말', value: '6' },
    ],
  },
  STMD_AT: {
    title: '튜닝 여부',
    defaultValue: 'N',
    valueItems: [
      { label: '없음', value: 'N' },
      { label: '있음', value: 'Y' },
    ],
    origin: '국토부 자동차기본정보',
    originKey: 'STMD_AT',
  },
  UNLAW_STMD_AT: {
    title: '튜닝 적법 여부',
    defaultValue: '0',
    valueItems: [
      // { label: '없음', value: '0' },
      { label: '적법', value: '1' },
      { label: '불법', value: '2' },
    ],
    // origin: '국토부 자동차기본정보',
    // originKey: 'STMD_AT',
  },
  UNLAW_STMD_IEM_SE_CODE: {
    title: '튜닝 항목 구분',
    defaultValue: '0',
    valueItems: [
      // {label: '없음', value: '0'},
      { label: '구조', value: '1' },
      { label: '장치', value: '2' },
    ],
  },
  ACDNT_AT: {
    title: '사고이력',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  SIMPL_REPAIR_AT: {
    title: '단순수리여부',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  FLUD_AT: {
    title: '특별 이력',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '침수', value: '1' },
      { label: '화재', value: '2' },
      { label: '전손', value: '3' },
    ],
  },
  PRPOS_CHANGE_SE_AT: {
    title: '용도 변경 여부',
    defaultValue: 'N',
    origin: '국토부 자동차기본정보',
    originKey: 'PRPOS_CHANGE_SE_AT',
    valueItems: [
      { label: '없음', value: 'N' },
      { label: '있음', value: 'Y' },
    ],
  },
  PRPOS_CHANGE_SE_CODE: {
    title: '용도 변경 구분 코드',
    defaultValue: '0',
    valueItems: [
      { label: '렌트', value: '1' },
      { label: '리스', value: '2' },
      { label: '영업용', value: '3' },
    ],
  },
  COLOR_AT: {
    title: '색상여부',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '무채색', value: '1' },
      { label: '유채색', value: '2' },
    ],
  },
  COLOR_CHANGE_AT: {
    title: '색상변경여부',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '전체도색', value: '1' },
      { label: '색상변경', value: '2' },
    ],
  },
  MAIN_OPTN_VHCLE_RF_AT: {
    title: '주요옵션_썬루프',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  MAIN_OPTN_ROAD_GUIDANCE_AT: {
    title: '주요옵션_네비게이션',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  MAIN_OPTN_ETC_AT: {
    title: '주요옵션_기타',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  RECALL_TRGET_AT: {
    title: '리콜대상 여부',
    defaultValue: '0',
    valueItems: [
      { label: '해당없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  RECALL_FLFL_AT: {
    title: '리콜이행 여부',
    defaultValue: '0',
    valueItems: [
      { label: '미이행', value: '0' },
      { label: '이행', value: '1' },
    ],
  },
  ENGINE_STBLT_AT: {
    title: '원동기',
    defaultValue: '1',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '2' },
    ],
  },
  GRBX_STBLT_AT: {
    title: '변속기',
    defaultValue: '1',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '2' },
    ],
  },
  SMOKE_MESURE_VALUE: {
    title: '배출가스 매연측정값',
    defaultValue: 0,
  },
  CRMN_MESURE_VALUE: {
    title: '배출가스 일산화탄소 측정값',
    defaultValue: 0.0,
  },
  HYDR_MESURE_VALUE: {
    title: '배출가스 탄화수소 측정값',
    defaultValue: 0,
  },
  ETC_MATTER: {
    title: '특이사항 및 점검자의견',
    defaultValue:
      '비금속(FRP, 플라스틱)의 탈부착 가능 부품은 점검사항에서 제외되며 중고차 특성 상 부분적인 판금, 도색, 차량의 노후화에 따른 자연스러운 부식이 있을 수 있습니다.',
  },
  CHCK_ENTRPS_BIZ_NO: {
    title: '점검업체 사업자등록번호',
    defaultValue: '',
  },
  CHCK_IMPRMN_ENTRPS_ID: {
    title: '점검정비업체번호',
    defaultValue: '',
  },
  CHCK_ENTRPS_NM: {
    title: '점검법인명',
    defaultValue: '',
  },
  INSCTR_NM: {
    title: '점검자명',
    defaultValue: '',
  },
  CHCK_DE: {
    title: '점검일',
    defaultValue: '',
  },
  NTIC_ENTRPS_ID: {
    title: '고지업체(매매업체)번호',
    defaultValue: '',
  },
  NTIC_ENTRPS_NM: {
    title: '고지업체명',
    defaultValue: '',
  },
  NTIC_PERSON_NM: {
    title: '고지사람명',
    defaultValue: '',
  },
  PC_EXAMIN_CALC_ENTRPS_NM: {
    title: '가격조사산정업체명',
    defaultValue: '',
  },
  PC_EXAMIN_CALC_CHARGER_NM: {
    title: '가격조사산정담당자명',
    defaultValue: '',
  },
  PC_EXAMIN_CALC_DE: {
    title: '가격조사산정일',
    defaultValue: '',
  },
  SPCABL_MATTER_ND_CALC_RESN: {
    title: '특이사항 및 산정자의견',
    defaultValue: '',
  },
  PC_CALC_STDR_ASOC_CODE: {
    title: '가격산정기준협회코드',
    defaultValue: '',
    valueItems: [
      { label: '기술사회', value: '0' },
      { label: '한국자동차진단보증협회', value: '1' },
    ],
  },
  ICNY_CODE: {
    title: '보험사코드',
    defaultValue: '',
  },
  PRFOMNC_CHCK_NO: {
    title: '성능점검번호',
    defaultValue: '',
  },
  EXTRR_REPAIR_NEED_AT: {
    title: '외장수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  ITRDECO_REPAIR_NEED_AT: {
    title: '내장수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  METAL_SURFC_CLNSG_NEED_AT: {
    title: '광택수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  ENGINE_ROOM_CLN_REPAIR_NEED_AT: {
    title: '룸크리닝수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_DRV_SEAT_FRNT_REPAIR_AT: {
    title: '휠_운전좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_DRV_SEAT_AFTR_REPAIR_AT: {
    title: '휠_운전좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_ACPSEAT_FRNT_REPAIR_AT: {
    title: '휠_동반좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_ACPSEAT_AFTR_REPAIR_AT: {
    title: '휠_동반좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_EMGNCY_REPAIR_NEED_AT: {
    title: '휠_응급수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_DRV_SEAT_FRNT_REPAIR_AT: {
    title: '타이어_운전좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_DRV_SEAT_AFTR_REPAIR_AT: {
    title: '타이어_운전좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_ACPSEAT_FRNT_REPAIR_AT: {
    title: '타이어_동반좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_ACPSEAT_AFTR_REPAIR_AT: {
    title: '타이어_동반좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_EMGNCY_REPAIR_NEED_AT: {
    title: '타이어_응급수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  GLASS_REPAIR_NEED_AT: {
    title: '유리수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_USE_MNL_AT: {
    title: '기본품목보유_사용설명서여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_TH_BRAKT_AT: {
    title: '기본품목보유_안전삼각대여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_TOOL_AT: {
    title: '기본품목보유_잭여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_TQWRNCH_AT: {
    title: '기본품목보유_스패너여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BUYER_DE: {
    title: '매수일',
    defaultValue: '',
  },
  BUYER_NM: {
    title: '매수자명',
    defaultValue: '',
  },
  PC_CALC_CHOISE_AT: {
    title: '가격산정선택여부',
    defaultValue: '0',
    valueItems: [
      { label: '선택안함', value: '0' },
      { label: '선택', value: '1' },
    ],
  },
  UPD_CODE: {
    title: '경정구분',
    defaultValue: 'N',
    valueItems: [
      { label: '신규 등록', value: 'N' },
      { label: '기존건 수정 경정', value: 'U' },
      { label: '기존건 삭제 경정', value: 'D' },
    ],
  },
  UPD_REASON: {
    title: '경정사유',
    defaultValue: '',
  },
  AGENT_CODE: {
    title: '연계기관코드',
    defaultValue: '',
  },
};

const PC_STATUS_BLUEPRINT = {
  s001: {
    title: '작동상태 (공회전)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s057: {
    title: '실린더 커버 (로커암 커버)',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s003: {
    title: '실린더 헤드 / 개스킷',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s058: {
    title: '실린더 블록 / 오일팬',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s005: {
    title: '오일 유량',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
    ],
  },
  s007: {
    title: '실린더 헤드 / 개스킷',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누수', value: '2' },
      { label: '누수', value: '3' },
    ],
  },
  s008: {
    title: '워터펌프',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누수', value: '2' },
      { label: '누수', value: '3' },
    ],
  },
  s009: {
    title: '라디에이터',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누수', value: '2' },
      { label: '누수', value: '3' },
    ],
  },
  s010: {
    title: '냉각수 수량',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
    ],
  },
  s011: {
    title: '커먼레일(디젤)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s012: {
    title: '오일누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s013: {
    title: '오일유량 및 상태',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
      { label: '과다', value: '3' },
    ],
  },
  s016: {
    title: '작동상태 (공회전)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '6' },
    ],
  },
  s017: {
    title: '오일누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s018: {
    title: '기어변속장치',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s019: {
    title: '오일유량 및 상태',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
      { label: '과다', value: '3' },
    ],
  },
  s020: {
    title: '작동상태 (공회전)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '6' },
    ],
  },
  s021: {
    title: '클러치 어셈블리(수동)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '7' },
    ],
  },
  s022: {
    title: '등속조인트',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s023: {
    title: '추친축 및 베어링',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s060: {
    title: '디퍼렌셜 기어',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s024: {
    title: '동력조향 작동 오일 누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s025: {
    title: '스티어링 기어 (MDPS포함)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s026: {
    title: '스티어링 펌프',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s061: {
    title: '스티어링 조인트',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s062: {
    title: '파워고압호스',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s027: {
    title: '타이로드엔드 및 볼 조인트',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s059: {
    title: '브레이크 마스터 실린더 오일 누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s029: {
    title: '브레이크 오일 누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s030: {
    title: '배력장치 상태',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s031: {
    title: '발전기 출력',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s055: {
    title: '시동 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s032: {
    title: '와이퍼 모터 기능',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s033: {
    title: '실내송풍 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s034: {
    title: '라디에이터 팬 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s036: {
    title: '윈도우 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s063: {
    title: '충전구 절연 상태',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s064: {
    title: '구동축전지 격리 상태',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s065: {
    title: '고전원전기배선 상태 (접속단자, 피복, 보호기구)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s035: {
    title: '연료누출 (LP 가스 포함)',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '있음', value: '2' },
    ],
  },
  s037: {
    title: '후드',
    defaultValue: '1',
  },
  s038: {
    title: '프론트펜더',
    defaultValue: '1',
  },
  s039: {
    title: '도어',
    defaultValue: '1',
  },
  s040: {
    title: '트렁크리드',
    defaultValue: '1',
  },
  s041: {
    title: '라디에이터 서포트(볼트체결부품)',
    defaultValue: '1',
  },
  s042: {
    title: '루프패널',
    defaultValue: '1',
  },
  s043: {
    title: '쿼터패널(리얼펜더)',
    defaultValue: '1',
  },
  s044: {
    title: '사이드실패널',
    defaultValue: '1',
  },
  s045: {
    defaultValue: '1',
    title: '프론트패널',
  },
  s046: {
    defaultValue: '1',
    title: '크로스멤버',
  },
  s047: {
    defaultValue: '1',
    title: '인사이드패널',
  },
  s048: {
    defaultValue: '1',
    title: '사이드멤버',
  },
  s049: {
    defaultValue: '1',
    title: '휠하우스',
  },
  s050: {
    defaultValue: '1',
    title: '대쉬패널',
  },
  s051: {
    defaultValue: '1',
    title: '플로어패널',
  },
  s052: {
    defaultValue: '1',
    title: '필러패널',
  },
  s053: {
    defaultValue: '1',
    title: '리어패널',
  },
  s054: {
    defaultValue: '1',
    title: '트렁크플로어',
  },
  s056: {
    defaultValue: '1',
    title: '패키지트레이',
  },
};

const PC_ADD_BLUEPRINT = {
  carInteriorNote: {
    title: '내장',
    defaultValue: '',
  },
  roomCleaningNote: {
    title: '룸크리닝',
    defaultValue: '',
  },
  carWheelFL: {
    title: '휠 - 운전석 앞',
    defaultValue: 1,
    valueItems: [
      { label: '양호', value: 1 },
      { label: '불량', value: 5 },
    ],
  },
  carWheelFR: {
    title: '휠 - 조수석 앞',
    defaultValue: 1,
    valueItems: [
      { label: '양호', value: 1 },
      { label: '불량', value: 5 },
    ],
  },
  carWheelRL: {
    title: '휠 - 운전석 뒤',
    defaultValue: 1,
    valueItems: [
      { label: '양호', value: 1 },
      { label: '불량', value: 5 },
    ],
  },
  carWheelRR: {
    title: '휠 - 조수석 뒤',
    defaultValue: 1,
    valueItems: [
      { label: '양호', value: 1 },
      { label: '불량', value: 5 },
    ],
  },
  tiretreadFL: {
    title: '타이어 트레드 - 운전석 앞',
    defaultValue: 0,
    suffix: 'mm',
  },
  tiretreadFR: {
    title: '타이어 트레드 - 조수석 앞',
    defaultValue: 0,
    suffix: 'mm',
  },
  tiretreadRL: {
    title: '타이어 트레드 - 운전석 뒤',
    defaultValue: 0,
    suffix: 'mm',
  },
  tiretreadRR: {
    title: '타이어 트레드 - 조수석 뒤',
    defaultValue: 0,
    suffix: 'mm',
  },
  brakePadFL: {
    title: '브레이크 패드 - 운전석 앞',
    defaultValue: 0.0,
    suffix: '%',
  },
  brakePadFR: {
    title: '브레이크 패드 - 조수석 앞',
    defaultValue: 0.0,
    suffix: '%',
  },
  brakePadRL: {
    title: '브레이크 패드 - 운전석 뒤',
    defaultValue: 0.0,
    suffix: '%',
  },
  brakePadRR: {
    title: '브레이크 패드 - 조수석 뒤',
    defaultValue: 0.0,
    suffix: '%',
  },
  tireyearFL: {
    title: '타이어 연식 - 운전석 앞',
    defaultValue: 0,
    suffix: '년',
  },
  tireyearFR: {
    title: '타이어 연식 - 조수석 앞',
    defaultValue: 0,
    suffix: '년',
  },
  tireyearRL: {
    title: '타이어 연식 - 운전석 뒤',
    defaultValue: 0,
    suffix: '년',
  },
  tireyearRR: {
    title: '타이어 연식 - 조수석 뒤',
    defaultValue: 0,
    suffix: '년',
  },
};

const PARTS_LEFT = [
  {
    partPoint: 'pp_162',
    partNumber: '6',
    partSubNumber: '',
    dataKey: 's043',
    title: '쿼터패널(좌측리어펜더)',
    pos: [337.5, 80],
    rpos: [337.5 - 115, 80 - 20],
    element:
      "<path d='M167.1,1073v129.6c25.5,5.7,50.5,2.9,74.1-1.3,0,0,0,0,.1,0,32.6-7.2,97.5-45.5,98.2-46l.6-.4h37.8c1.4-12,3.1-24.9,4.7-37.5,1.7-13.4,3.5-27.2,5-39.8l.3-2.3h17.2c4.7-20.4,12.8-38.6,27.6-55.6,21.1-24,41.7-50.5,62.8-80.7-4.4-1.9-9.3-4.2-14.2-6.4-5.8-2.6-11.7-5.3-16.8-7.5l-138.4,32.7-.8-.4c-12-6-25.1-12.6-37.7-18.9-10.4-5.3-21.2-10.7-31.2-15.7,12,47.6-2.6,90.2-41.5,120.2-10.6,8.2-22.4,15-33.8,21.6-4.7,2.7-9.5,5.5-14.1,8.3Z'/>",
  },
  {
    partPoint: 'pp_22',
    partNumber: '2',
    partSubNumber: '',
    dataKey: 's038',
    title: '프론트펜더(좌)',
    pos: [40, 80],
    rpos: [40 - 18, 80 - 23],
    element:
      "<path d='M156,117.1v69.5c14.8,1.4,29.2,5.7,41.5,11.8,38.8,19.3,74.4,65.3,57.6,136.5-5.4,22.8-19.4,45.4-38.6,62-.3.2-.6.5-.9.7l148.7,2.3c-1.9-12.9-3.8-25.9-5.7-39.1-4.8-32.5-9.7-66.1-14.3-99.6-8.4-61.7-32-109.2-72.3-145.1-34.4-30.6-66.4-41.1-103.3-33.6v32.7s-4.7.7-4.7.7c-2.7.4-5.4.9-8.2,1.2Z'/>",
  },
  {
    partPoint: 'pp_81',
    partNumber: '8',
    partSubNumber: '',
    dataKey: 's044',
    title: '사이드실패널',
    pos: [170, 30],
    rpos: [170 - 40, 30 - 5.5],
    element:
      "<polygon points='98 621.4 98 812.4 135.8 812.4 135.8 703.9 135.4 621.4 98 621.4'/>",
  },
  {
    partPoint: 'pp_82',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(좌측전방)',
    pos: [160, 80],
    rpos: [160 - 58, 80 - 23],
    element:
      "<path d='M139.7,443.7l1.2,244c7.4-8.1,18.5-7.7,30.1-7.3,2,0,4,.2,6.1.2,22.7.4,45.8.3,68.1.2,22.8,0,46.3-.1,69.4.3,15.4.3,30.5,1.7,46.5,3.2,6.3.6,12.8,1.2,19.5,1.7l-13.8-234.4c-91.9-27.6-211.9-10.3-227.1-7.9ZM325.8,598.8c0-8.2,6.6-14.8,14.8-14.8s14.8,6.6,14.8,14.8v31.4c0,8.2-6.6,14.8-14.8,14.8s-14.8-6.6-14.8-14.8v-31.4Z'/>",
  },
  {
    partPoint: 'pp_102',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(좌측후방)',
    pos: [234, 80],
    rpos: [234 - 82, 80 - 25],
    element:
      "<path d='M141,697.1v132.4c2.6.3,5.8.8,6.9.9,3.4.5,6.8,1,10.3,1.5,48.2,7.6,82.3,37.6,96.5,84.4,11.1,5.5,23.4,11.8,35.3,17.8,12.3,6.2,25,12.6,36.8,18.5l68.2-16.1c-2.7-39.3-4.8-80.5-6.9-120.4-2.1-41-4.2-83.4-7.1-124.9-6.9-.6-13.7-1.2-20.3-1.8-15.9-1.5-30.9-2.9-46.1-3.1-23.1-.4-46.6-.3-69.3-.3-22.4,0-45.5.1-68.2-.2-2.1,0-4.2-.1-6.2-.2-14.2-.5-24.5-.9-29.9,11.5ZM350.2,875.7c0-8.2,6.6-14.8,14.8-14.8,8.2,0,14.8,6.6,14.8,14.8v31.5c0,8.2-6.6,14.8-14.8,14.8-8.2,0-14.8-6.6-14.8-14.8v-31.5Z'/>",
  },
  {
    partPoint: 'pp_103',
    partNumber: '14',
    partSubNumber: 'B',
    dataKey: 's052',
    title: '필러패널(B)',
    pos: [205, 135],
    rpos: [205 - 72, 135 - 46],
    element:
      "<path d='M384.8,668.4l1.2,20.3c.8,11.4,1.5,22.8,2.2,34.3l168.6,5.1c.3-12,.3-24-.1-35.9-.1-2.9-.3-5.8-.4-8.7-1.2,0-2.5,0-3.9.1-2.7,0-5.9.2-9.6.2s-2.3,0-3.5,0c-37.8-.7-77.7-5.8-116.3-10.7-12.6-1.6-25.7-3.2-38.2-4.7Z'/>",
  },
  {
    partPoint: 'pp_144',
    partNumber: '14',
    partSubNumber: 'C',
    dataKey: 's052',
    title: '필러패널(C)',
    pos: [300, 130],
    rpos: [300 - 105, 130 - 40],
    element: "<circle cx='423' cy='974' r='33.8'/>",
  },
  {
    partPoint: 'pp_63',
    partNumber: '14',
    partSubNumber: 'A',
    dataKey: 's052',
    title: '필러패널(A)',
    pos: [120, 120],
    rpos: [120 - 45, 120 - 40],
    element: "<circle cx='374.7' cy='433.8' r='33.8'/>",
  },
];

const PARTS_RIGHT = [
  {
    partPoint: 'pp_179',
    partNumber: '6',
    partSubNumber: '',
    dataKey: 's043',
    title: '쿼터패널(우측리어펜더)',
    pos: [339, 110],
    rpos: [332 - 114, 153 - 82],
    element:
      '<path d="M487.9,1073v129.6c-25.5,5.7-50.5,2.9-74.1-1.3,0,0,0,0-.1,0-32.6-7.2-97.5-45.5-98.2-46l-.6-.4h-37.8c-1.4-12-3.1-24.9-4.7-37.5-1.7-13.4-3.5-27.2-5-39.8l-.3-2.3h-17.2c-4.7-20.4-12.8-38.6-27.6-55.6-21.1-24-41.7-50.5-62.8-80.7,4.4-1.9,9.3-4.2,14.2-6.4,5.8-2.6,11.7-5.3,16.8-7.5l138.4,32.7.8-.4c12-6,25.1-12.6,37.7-18.9,10.4-5.3,21.2-10.7,31.2-15.7-12,47.6,2.6,90.2,41.5,120.2,10.6,8.2,22.4,15,33.8,21.6,4.7,2.7,9.5,5.5,14.1,8.3Z"/>',
  },
  {
    partPoint: 'pp_39',
    partNumber: '2',
    partSubNumber: '',
    dataKey: 's038',
    title: '프론트펜더(우)',
    pos: [46, 105],
    rpos: [46 - 18, 105 - 32],
    element:
      "<path d='M499,117.1v69.5c-14.8,1.4-29.2,5.7-41.5,11.8-38.8,19.3-74.4,65.3-57.6,136.5,5.4,22.8,19.4,45.4,38.6,62,.3.2.6.5.9.7l-148.7,2.3c1.9-12.9,3.8-25.9,5.7-39.1,4.8-32.5,9.7-66.1,14.3-99.6,8.4-61.7,32-109.2,72.3-145.1,34.4-30.6,66.4-41.1,103.3-33.6v32.7l4.7.7c2.7.4,5.4.9,8.2,1.2Z'/>",
  },

  {
    partPoint: 'pp_100',
    partNumber: '8',
    partSubNumber: '',
    dataKey: 's044',
    title: '사이드실패널',
    pos: [166, 160],
    rpos: [166 - 36, 160 - 50],
    element:
      "<path d='M557 621.4L557 812.4L519.2 812.4L519.2 703.9L519.6 621.4L557 621.4z' />",
  },
  {
    partPoint: 'pp_99',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(우측전방)',
    pos: [155, 100],
    rpos: [155 - 52, 100 - 28],
    element:
      '<path d="M288.1,451.7l-13.8,234.4c6.6-.5,13.1-1.1,19.5-1.7,16-1.5,31.2-2.9,46.5-3.2,23.2-.4,46.7-.3,69.4-.3,22.3,0,45.4.1,68.1-.2,2.1,0,4.1-.1,6.1-.2,11.6-.4,22.7-.8,30.1,7.3l1.2-244c-15.2-2.4-135.2-19.6-227.1,7.9ZM329.2,630.3c0,8.2-6.6,14.8-14.8,14.8s-14.8-6.6-14.8-14.8v-31.4c0-8.2,6.6-14.8,14.8-14.8s14.8,6.6,14.8,14.8v31.4Z"/>',
  },
  {
    partPoint: 'pp_139',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(우측후방)',
    pos: [235, 100],
    rpos: [235 - 83, 100 - 28],
    element:
      '<path d="M484.2,685.6c-2,0-4.1.1-6.2.2-22.7.4-45.9.3-68.2.2-22.7,0-46.2-.1-69.3.3-15.2.3-30.2,1.7-46.1,3.1-6.6.6-13.4,1.2-20.3,1.8-2.9,41.5-5.1,83.8-7.1,124.9-2,39.9-4.1,81.1-6.9,120.4l68.2,16.1c11.8-5.9,24.5-12.3,36.8-18.5,11.9-6,24.2-12.2,35.3-17.8,14.2-46.9,48.3-76.8,96.5-84.4,3.4-.5,6.9-1,10.3-1.5,1.1-.1,4.3-.6,6.9-.9v-132.4c-5.4-12.4-15.7-12-29.9-11.5ZM304.8,907.2c0,8.2-6.6,14.8-14.8,14.8s-14.8-6.6-14.8-14.8v-31.5c0-8.2,6.6-14.8,14.8-14.8s14.8,6.6,14.8,14.8v31.5Z"/>',
  },
  {
    partPoint: 'pp_118',
    partNumber: '14',
    partSubNumber: 'B',
    dataKey: 's052',
    title: '필러패널(B)',
    pos: [205, 55],
    rpos: [205 - 70, 55 - 20],
    element:
      "<path d='M270.2,668.4l-1.2,20.3c-.8,11.4-1.5,22.8-2.2,34.3l-168.6,5.1c-.3-12-.3-24,.1-35.9.1-2.9.3-5.8.4-8.7,1.2,0,2.5,0,3.9.1,2.7,0,5.9.2,9.6.2s2.3,0,3.5,0c37.8-.7,77.7-5.8,116.3-10.7,12.6-1.6,25.7-3.2,38.2-4.7Z'/>",
  },
  {
    partPoint: 'pp_78',
    partNumber: '14',
    partSubNumber: 'A',
    dataKey: 's052',
    title: '필러패널(A)',
    pos: [120, 80],
    rpos: [120 - 45, 80 - 28],
    element: "<circle cx='275.3' cy='433.8' r='33.8'/>",
  },
  {
    partPoint: 'pp_137',
    partNumber: '14',
    partSubNumber: 'C',
    dataKey: 's052',
    title: '필러패널(C)',
    pos: [300, 60],
    rpos: [300 - 105, 60 - 20],
    element: "<circle cx='225' cy='976.5' r='33.8'/>",
  },
];

const PARTS_TOP = [
  {
    partPoint: 'pp_28',
    partNumber: '1',
    partSubNumber: '',
    dataKey: 's037',
    title: '후드',
    pos: [50, 140],
    rpos: [50 - 17, 140 - 55],
    element:
      "<path d='M521.43,118.73c-13.58-35.26-20.52-38.09-51.1-45.67-2.98-.75-6.23-1.55-9.79-2.47-42.38-10.91-87.16-16.13-131.04-15.74-43.88-.39-88.66,4.83-131.04,15.74-3.56.92-6.81,1.72-9.79,2.47-30.58,7.58-37.52,10.41-51.1,45.67-18.24,47.38-13.5,103.13-9.31,152.32.31,3.65.62,7.26.91,10.85h0c.45,5.43,6.07,8.83,11.09,6.71h0c58.95-24.9,123.96-38.22,188-38.54.41,0,.83,0,1.24,0,.41,0,.83,0,1.24,0,64.04.32,129.05,13.64,188,38.54h0c5.02,2.12,10.64-1.28,11.09-6.71h0c.29-3.59.6-7.2.91-10.85,4.19-49.19,8.93-104.94-9.31-152.32Z'/>",
  },
  {
    partPoint: 'pp_107',
    partNumber: '7',
    partSubNumber: '',
    dataKey: 's042',
    title: '루프패널',
    pos: [200, 140],
    rpos: [200 - 70, 140 - 55],
    element:
      "<path d='M473.05,405.7l.42-6.36-6.12-1.83c-43.65-13.03-90.42-19.38-137.85-19.04-47.43-.34-94.2,6.01-137.85,19.04l-6.12,1.83.42,6.36c1.2,18.3,2.74,36.98,4.23,55.06,5.93,71.98,12.06,146.42-1.05,217.66l-1.58,8.56,8.66.85c43.52,4.29,87.3,6.44,131.06,6.44.74,0,1.49,0,2.23-.01.74.01,1.49.01,2.23.01,43.76,0,87.54-2.15,131.06-6.44l8.66-.85-1.58-8.56c-13.11-71.24-6.98-145.68-1.05-217.66,1.49-18.08,3.03-36.76,4.23-55.06Z' />",
  },
  {
    partPoint: 'pp_168',
    partNumber: '4',
    partSubNumber: '',
    dataKey: 's040',
    title: '트렁크리드',
    pos: [330, 140],
    rpos: [330 - 116, 140 - 55],
    element:
      "<path d='M511.17,760.65c-3.57-.87-8.26-.14-49.75,9.58-20.36,4.76-43.43,10.16-51.36,11.04-24.08,2.66-49.08,3.66-75.17,2.98-3.59-.09-7.19-.09-10.79,0-26.09.68-51.08-.33-75.17-2.98-7.93-.88-31-6.28-51.36-11.04-41.49-9.72-46.18-10.45-49.75-9.58-4.95,1.2-9.08,5.38-10.79,10.91h0c-.36,1.19-.45,2.43-.25,3.65h0c1.66,10.15,1.5,27.36,1.33,45.59-.27,28.25-.55,57.46,5.85,71.43,1.17,2.53,3.34,7.25,30.75,17.49,40.01,14.99,96.36,21.64,151.87,21.64.97,0,1.94,0,2.91-.01.97.01,1.94.01,2.91.01,55.51,0,111.86-6.65,151.87-21.64,27.41-10.24,29.58-14.96,30.75-17.49,6.4-13.97,6.12-43.18,5.85-71.43-.17-18.23-.33-35.44,1.33-45.59h0c.2-1.22.11-2.46-.25-3.64h0c-1.71-5.54-5.84-9.72-10.79-10.92Z'/>",
  },
];

const PARTS_BOTTOM = [
  {
    partPoint: 'pp_14',
    partNumber: '5',
    partSubNumber: '',
    dataKey: 's041',
    title: '라디에이터서포트(볼트체결부품)',
    pos: [7, 100],
    rpos: [7 - 4.5, 166 - 97],
    element:
      "<path class='st0' d='M515,80H145c0-5.09,1.03-9.93,2.89-14.34,13.29-6.76,34.99-15.39,68.36-22.66h221.5c40.55,8.83,63.87,19.68,75.8,26.71h0c.95,3.27,1.45,6.72,1.45,10.29Z'/>",
  },
  {
    partPoint: 'pp_33',
    partNumber: '9',
    partSubNumber: '',
    dataKey: 's045',
    title: '프론트패널',
    pos: [34, 100],
    rpos: [34 - 20.5, 100 - 31],
    element: "<rect class='st0' x='145' y='90' width='370' height='37'/>",
  },
  {
    partPoint: 'pp_32',
    partNumber: '11',
    partSubNumber: '',
    dataKey: 's047',
    title: '인사이드패널(좌)',
    pos: [46, 35],
    rpos: [46 - 8.5, 84 - 56],
    element: "<rect class='st0' x='113' y='141' width='64' height='137'/>",
  },
  {
    partPoint: 'pp_34',
    partNumber: '11',
    partSubNumber: '',
    dataKey: 's047',
    title: '인사이드패널(우)',
    pos: [46, 160],
    rpos: [46 - 6.5, 160 - 50],
    element: "<rect class='st0' x='482' y='141' width='64' height='137'/>",
  },
  {
    partPoint: 'pp_53',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(좌측전방)',
    pos: [72, 62],
    rpos: [72 - 16.5, 62 - 15],
    element:
      "<polygon class='st0' points='259.5 363 195.5 363 195.5 141 235.5 141 235.5 232.45 259.5 252 259.5 363'/>",
  },
  {
    partPoint: 'pp_54',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(우측전방)',
    pos: [72, 132],
    rpos: [72 - 16.5, 132 - 40],
    element:
      "<polygon class='st0' points='400.5 363 464.5 363 464.5 141 424.5 141 424.5 232.45 400.5 252 400.5 363'/>",
  },
  {
    partPoint: 'pp_52',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(좌측전방)',
    pos: [98, 35],
    rpos: [98 - 36, 35 - 8],
    element: "<circle class='st0' cx='145' cy='325' r='38'/>",
  },
  {
    partPoint: 'pp_55',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(우측전방)',
    pos: [98, 160],
    rpos: [98 - 36, 160 - 50],
    element: "<circle class='st0' cx='512' cy='325' r='38'/>",
  },
  {
    partPoint: 'pp_73',
    partNumber: '10',
    partSubNumber: '',
    dataKey: 's046',
    title: '크로스멤버',
    pos: [115, 100],
    rpos: [115 - 37.5, 100 - 30],
    element:
      "<rect class='st0' x='116' y='380' width='428' height='37' rx='18.5' ry='18.5'/>",
  },
  {
    partPoint: 'pp_74',
    partNumber: '15',
    partSubNumber: '',
    dataKey: 's050',
    title: '대쉬패널',
    pos: [144, 100],
    rpos: [144 - 55, 100 - 30],
    element: "<rect class='st0' x='145' y='431' width='370' height='37'/>",
  },
  {
    partPoint: 'pp_94',
    partNumber: '16',
    partSubNumber: '',
    dataKey: 's051',
    title: '플로어패널',
    pos: [182, 100],
    rpos: [182 - 62, 100 - 30],
    element:
      "<rect class='st0' x='149' y='482' width='362' height='212' rx='35.15' ry='35.15'/>",
  },
  {
    partPoint: 'pp_153',
    partNumber: '19',
    partSubNumber: '',
    dataKey: 's056',
    title: '패키지트레이',
    pos: [286, 100],
    rpos: [286 - 98, 100 - 30],
    element: "<ellipse class='st0' cx='328' cy='891' rx='60' ry='27'/>",
  },
  {
    partPoint: 'pp_152',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(좌측후방)',
    pos: [295, 35],
    rpos: [295 - 102, 35 - 10],
    element: "<circle class='st0' cx='134.5' cy='914' r='38'/>",
  },
  {
    partPoint: 'pp_154',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(우측후방)',
    pos: [295, 160],
    rpos: [295 - 102, 160 - 50],
    element: "<circle class='st0' cx='517.5' cy='914' r='38'/>",
  },
  {
    partPoint: 'pp_172',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(좌측후방)',
    pos: [336, 35],
    rpos: [336 - 116, 35 - 5],
    element:
      "<path class='st0' d='M113.5,971h54.94c6.1,0,11.06,4.95,11.06,11.06v112.89c0,6.1-4.95,11.06-11.06,11.06h-54.94v-135h0Z' transform='translate(293 2077) rotate(180)'/>",
  },
  {
    partPoint: 'pp_174',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(우측후방)',
    pos: [336, 160],
    rpos: [336 - 116, 160 - 50],
    element:
      "<path class='st0' d='M466.5,971h54.94c6.1,0,11.06,4.95,11.06,11.06v112.89c0,6.1-4.95,11.06-11.06,11.06h-54.94v-135h0Z'/>",
  },
  {
    partPoint: 'pp_173',
    partNumber: '17',
    partSubNumber: '',
    dataKey: 's054',
    title: '트렁크플로어',
    pos: [335, 100],
    rpos: [335 - 114, 100 - 30],
    element: "<ellipse class='st0' cx='333' cy='1024.5' rx='107' ry='64.5'/>",
  },
  {
    partPoint: 'pp_194',
    partNumber: '18',
    partSubNumber: '',
    dataKey: 's053',
    title: '리어패널',
    pos: [372, 100],
    rpos: [372 - 128, 100 - 30],
    element:
      "<rect class='st0' x='147' y='1118' width='361' height='43' rx='6.62' ry='6.62'/>",
  },
];

const ADD_PARTS_LEFT = [
  {
    partPoint: 'ap_1',
    partNumber: '2',
    title: '프론트펜더(좌)',
    pos: ['10%', '36%'],
    reportPos: [40 - 10, 80 - 25],
    category: '외장',
    element:
      "<path d='M25.8707 16.4823V47.5052C32.4722 48.1301 38.8953 50.0495 44.3817 52.7724C61.6883 61.3874 77.5676 81.9205 70.074 113.702C67.6653 123.879 61.4207 133.967 52.8566 141.377C52.7228 141.467 52.589 141.6 52.4551 141.69L118.782 142.716C117.935 136.958 117.087 131.155 116.24 125.263C114.099 110.756 111.913 95.758 109.861 80.8046C106.115 53.2634 95.5879 32.0607 77.6122 16.036C62.2682 2.37697 47.9947 -2.30993 31.5355 1.03786V15.6342L29.4391 15.9467C28.2348 16.1252 27.0304 16.3484 25.7815 16.4823H25.8707Z'/>",
  },
  {
    partPoint: 'ap_2',
    partNumber: '14',
    title: '필러패널(A)',
    pos: ['30%', '55%'],
    reportPos: [120 - 50, 120 - 35],
    category: '외장',
    element:
      "<path d='M125.651 172.936C117.325 172.936 110.575 166.181 110.575 157.848C110.575 149.516 117.325 142.761 125.651 142.761C133.978 142.761 140.728 149.516 140.728 157.848C140.728 166.181 133.978 172.936 125.651 172.936Z'/>",
  },
  {
    partPoint: 'ap_3',
    partNumber: '8',
    title: '사이드실패널(좌)',
    pos: ['50%', '15%'],
    reportPos: [200 - 70, 35 - 70],
    category: '외장',
    element:
      "<path d='M2.22626e-06 241.588V326.845H16.8606V278.413L16.6822 241.588H2.22626e-06Z'/>",
  },
  {
    partPoint: 'ap_4',
    partNumber: '3',
    title: '도어(좌측전방)',
    pos: ['42.5%', '37.5%'],
    reportPos: [160 - 60, 80 - 70],
    category: '외장',
    element:
      "<path d='M119.942 165.838L126.098 270.468C123.154 270.245 120.254 269.977 117.4 269.709C110.263 269.04 103.483 268.415 96.6584 268.281C86.3101 268.102 75.828 268.147 65.7027 268.147C55.7559 268.147 45.4522 268.192 35.3269 268.058C34.3902 268.058 33.4981 268.013 32.606 267.968C27.4319 267.79 22.4808 267.611 19.18 271.227L18.6447 162.312C25.4247 161.241 78.9503 153.563 119.942 165.838ZM101.61 245.56C101.61 249.221 104.553 252.167 108.211 252.167C111.869 252.167 114.813 249.221 114.813 245.56V231.544C114.813 227.884 111.869 224.938 108.211 224.938C104.553 224.938 101.61 227.884 101.61 231.544V245.56Z'/>",
  },
  {
    partPoint: 'ap_5',
    partNumber: '3',
    title: '도어(좌측후방)',
    pos: ['61.25%', '37.5%'],
    reportPos: [234 - 80, 80 - 70],
    category: '외장',
    element:
      "<path d='M32.4722 270.245C33.3643 270.245 34.301 270.289 35.2377 270.334C45.363 270.513 55.7113 270.468 65.6581 270.423C75.7834 270.423 86.2655 270.379 96.5692 270.557C103.349 270.691 110.04 271.316 117.132 271.941C120.076 272.209 123.109 272.477 126.187 272.744C127.48 291.269 128.462 310.15 129.354 328.496C130.246 346.307 131.182 364.697 132.431 382.24L102.011 389.426C96.7476 386.793 91.0828 383.936 85.5964 381.168C80.2885 378.49 74.8021 375.722 69.851 373.223C63.5171 352.288 48.3069 338.941 26.8074 335.549C25.2909 335.326 23.7297 335.103 22.2131 334.879C21.7225 334.835 20.2951 334.612 19.1354 334.478V275.378C21.5441 269.843 26.1383 270.022 32.4722 270.245ZM112.493 369.161C112.493 372.821 115.437 375.767 119.095 375.767C122.752 375.767 125.696 372.821 125.696 369.161V355.1C125.696 351.44 122.752 348.494 119.095 348.494C115.437 348.494 112.493 351.44 112.493 355.1V369.161Z'/>",
  },
  {
    partPoint: 'ap_6',
    partNumber: '14',
    title: '필러패널(B)',
    pos: ['51.25%', '62%'],
    reportPos: [205 - 75, 135 - 25],
    category: '외장',
    element:
      "<path d='M127.926 262.567L128.462 271.629C128.818 276.717 129.131 281.806 129.443 286.939L204.646 289.216C204.78 283.859 204.78 278.503 204.602 273.191C204.557 271.896 204.468 270.602 204.423 269.307C203.888 269.307 203.308 269.307 202.684 269.352C201.48 269.352 200.052 269.441 198.402 269.441C196.751 269.441 197.376 269.441 196.841 269.441C179.98 269.129 162.183 266.852 144.965 264.665C139.345 263.951 133.502 263.237 127.926 262.567Z'/>",
  },
  {
    partPoint: 'ap_7',
    partNumber: '14',
    title: '필러패널(C)',
    pos: ['75%', '62%'],
    reportPos: [300 - 110, 130 - 30],
    category: '외장',
    element:
      "<path d='M146.75 415.182C138.423 415.182 131.673 408.427 131.673 400.094C131.673 391.762 138.423 385.007 146.75 385.007C155.076 385.007 161.826 391.762 161.826 400.094C161.826 408.427 155.076 415.182 146.75 415.182Z'/>",
  },
  {
    partPoint: 'ap_8',
    partNumber: '6',
    title: '쿼터패널(좌측리어펜더)',
    pos: ['84%', '37.5%'],
    reportPos: [337.5 - 110, 80 - 30],
    category: '외장',
    element:
      "<path d='M30.8218 443.169V501.019C42.196 503.563 53.3472 502.314 63.8739 500.439C63.8739 500.439 63.8739 500.439 63.9185 500.439C78.4597 497.225 107.408 480.129 107.72 479.906L107.988 479.727H124.849C125.473 474.371 126.231 468.613 126.945 462.988C127.703 457.007 128.506 450.847 129.175 445.223L129.309 444.196H136.981C139.077 435.09 142.69 426.966 149.292 419.378C158.704 408.665 167.892 396.836 177.304 383.355C175.341 382.507 173.156 381.481 170.97 380.499C168.383 379.338 165.751 378.133 163.476 377.151L101.743 391.747L101.386 391.569C96.0339 388.891 90.1907 385.944 84.5705 383.132C79.9316 380.767 75.1143 378.356 70.6538 376.124C76.0064 397.372 69.4941 416.387 52.1429 429.778C47.4148 433.438 42.1514 436.474 37.0665 439.42C34.9701 440.625 32.829 441.875 30.7772 443.125L30.8218 443.169Z'/>",
  },
  {
    partPoint: 'ap_9',
    partNumber: '20',
    title: '유리(운전석앞)',
    pos: ['42.5%', '65%'],
    reportPos: [170 - 70, 150 - 55],
    category: '유리',
    element:
      "<path d='M190.357 268.203C196.118 218.824 148.864 182.133 124.518 169.959L129.661 262.031L190.357 268.203Z'/>",
  },
  {
    partPoint: 'ap_10',
    partNumber: '20',
    title: '유리(운전석뒤)',
    pos: ['61.25%', '65%'],
    reportPos: [245 - 90, 150 - 55],
    category: '유리',
    element:
      "<path d='M190.325 289.807H130.144L135.802 379.307L163.063 372.62C182.815 359.864 189.467 312.096 190.325 289.807Z'/>",
  },
];

const ADD_PARTS_RIGHT = [
  {
    partPoint: 'ap_11',
    partNumber: '2',
    title: '프론트펜더(우)',
    pos: ['11.5%', '47.7%'],
    reportPos: ['11.5%', '35%'],
    category: '외장',
    element:
      '<path d="M179.319 16.6955V47.7183C172.717 48.3432 166.294 50.2626 160.808 52.9855C143.501 61.6005 127.622 82.1336 135.116 113.915C137.524 124.093 143.769 134.181 152.333 141.59C152.467 141.68 152.601 141.814 152.734 141.903L86.4073 142.929C87.2548 137.171 88.1023 131.368 88.9497 125.476C91.0908 110.969 93.2764 95.9712 95.3282 81.0177C99.075 53.4765 109.602 32.2739 127.577 16.2491C142.921 2.59011 157.195 -2.0968 173.654 1.25099V15.8474L175.751 16.1598C176.955 16.3384 178.159 16.5616 179.408 16.6955H179.319Z"/>',
  },
  {
    partPoint: 'ap_12',
    partNumber: '14',
    title: '필러패널(A)',
    pos: ['30%', '31.8%'],
    reportPos: ['25%', '0%'],
    category: '외장',
    element:
      '<path d="M79.5382 173.149C87.8646 173.149 94.6146 166.394 94.6146 158.061C94.6146 149.729 87.8646 142.974 79.5382 142.974C71.2117 142.974 64.4618 149.729 64.4618 158.061C64.4618 166.394 71.2117 173.149 79.5382 173.149Z"/>',
  },
  {
    partPoint: 'ap_13',
    partNumber: '8',
    title: '사이드실패널(우)',
    pos: ['50%', '75%'],
    reportPos: ['45%', '60%'],
    category: '외장',
    element:
      '<path d="M205.19 241.801V327.058H188.329V278.627L188.507 241.801H205.19Z"/>',
  },
  {
    partPoint: 'ap_14',
    partNumber: '3',
    title: '도어(우측전방)',
    pos: ['40%', '50%'],
    reportPos: ['35%', '35%'],
    category: '외장',
    element:
      '<path d="M85.2476 166.052L79.0921 270.681C82.036 270.458 84.9354 270.19 87.7901 269.922C94.9268 269.253 101.707 268.628 108.531 268.494C118.88 268.315 129.362 268.36 139.487 268.36C149.434 268.36 159.737 268.405 169.863 268.271C170.799 268.271 171.692 268.226 172.584 268.181C177.758 268.003 182.709 267.824 186.01 271.44L186.545 162.525C179.765 161.454 126.239 153.776 85.2476 166.052ZM103.58 245.774C103.58 249.434 100.636 252.38 96.9786 252.38C93.321 252.38 90.3771 249.434 90.3771 245.774V231.757C90.3771 228.097 93.321 225.151 96.9786 225.151C100.636 225.151 103.58 228.097 103.58 231.757V245.774Z"/>',
  },
  {
    partPoint: 'ap_15',
    partNumber: '3',
    title: '도어(우측후방)',
    pos: ['58.8%', '50%'],
    reportPos: ['54%', '35%'],
    category: '외장',
    element:
      '<path d="M172.717 270.458C171.825 270.458 170.889 270.503 169.952 270.547C159.827 270.726 149.478 270.681 139.531 270.636C129.406 270.636 118.924 270.592 108.62 270.77C101.841 270.904 95.1498 271.529 88.0577 272.154C85.1137 272.422 82.0806 272.69 79.0029 272.958C77.7094 291.482 76.7281 310.364 75.836 328.709C74.9439 346.52 74.0072 364.91 72.7582 382.453L103.179 389.639C108.442 387.006 114.107 384.149 119.593 381.381C124.901 378.703 130.388 375.936 135.339 373.436C141.673 352.501 156.883 339.155 178.382 335.762C179.899 335.539 181.46 335.316 182.977 335.093C183.467 335.048 184.894 334.825 186.054 334.691V275.591C183.646 270.056 179.051 270.235 172.717 270.458ZM92.6965 369.374C92.6965 373.034 89.7526 375.98 86.095 375.98C82.4375 375.98 79.4936 373.034 79.4936 369.374V355.313C79.4936 351.653 82.4375 348.707 86.095 348.707C89.7526 348.707 92.6965 351.653 92.6965 355.313V369.374Z"/>',
  },
  {
    partPoint: 'ap_16',
    partNumber: '14',
    title: '필러패널(B)',
    pos: ['52.5%', '27.3%'],
    reportPos: ['45%', '-10%'],
    category: '외장',
    element:
      '<path d="M77.2633 262.78L76.7281 271.842C76.3712 276.93 76.059 282.019 75.7467 287.152L0.543198 289.429C0.409384 284.072 0.409387 278.716 0.587806 273.404C0.63241 272.11 0.721617 270.815 0.766222 269.521C1.30148 269.521 1.88134 269.521 2.50581 269.565C3.71013 269.565 5.13748 269.655 6.78786 269.655C8.43823 269.655 7.81377 269.655 8.34902 269.655C25.2096 269.342 43.0069 267.066 60.2243 264.878C65.8445 264.164 71.6877 263.45 77.2633 262.78Z"/>',
  },
  {
    partPoint: 'ap_17',
    partNumber: '14',
    title: '필러패널(C)',
    pos: ['75%', '28%'],
    reportPos: ['65%', '-5%'],
    category: '외장',
    element:
      '<path d="M58.4401 415.395C66.7666 415.395 73.5165 408.64 73.5165 400.308C73.5165 391.975 66.7666 385.22 58.4401 385.22C50.1137 385.22 43.3638 391.975 43.3638 400.308C43.3638 408.64 50.1137 415.395 58.4401 415.395Z"/>',
  },
  {
    partPoint: 'ap_18',
    partNumber: '6',
    title: '쿼터패널(우측리어펜더)',
    pos: ['84%', '47.7%'],
    reportPos: ['70%', '35%'],
    category: '외장',
    element:
      '<path d="M174.368 443.382V501.232C162.994 503.777 151.842 502.527 141.316 500.652C141.316 500.652 141.316 500.652 141.271 500.652C126.73 497.438 97.7815 480.342 97.4693 480.119L97.2016 479.94H80.3411C79.7166 474.584 78.9583 468.826 78.2446 463.201C77.4863 457.22 76.6835 451.06 76.0144 445.436L75.8806 444.409H68.2086C66.1121 435.303 62.4992 427.179 55.8977 419.591C46.4861 408.878 37.2975 397.049 27.8859 383.569C29.8485 382.721 32.0341 381.694 34.2198 380.712C36.8068 379.551 39.4385 378.346 41.7134 377.364L103.446 391.96L103.803 391.782C109.156 389.104 114.999 386.158 120.619 383.345C125.258 380.98 130.075 378.569 134.536 376.337C129.183 397.585 135.696 416.6 153.047 429.991C157.775 433.652 163.038 436.687 168.123 439.633C170.22 440.838 172.361 442.088 174.412 443.338L174.368 443.382Z"/>',
  },
  {
    partPoint: 'ap_19',
    partNumber: '20',
    title: '유리(조수석앞)',
    pos: [170, 50],
    reportPos: ['35%', '-10%'],
    category: '유리',
    element:
      '<path d="M14.833 268.417C9.0721 219.037 56.3254 182.346 80.6721 170.172L75.5284 262.244L14.833 268.417Z"/>',
  },
  {
    partPoint: 'ap_20',
    partNumber: '20',
    title: '유리(조수석뒤)',
    pos: [245, 50],
    reportPos: ['55%', '-10%'],
    category: '유리',
    element:
      '<path d="M14.8649 290.02H75.046L69.3879 379.52L42.1264 372.833C22.3747 360.077 15.7222 312.309 14.8649 290.02Z"/>',
  },
];

const ADD_PARTS_TOP = [
  {
    partPoint: 'ap_21',
    partNumber: '1',
    title: '후드',
    pos: ['13%', '48%'],
    reportPos: ['13%', '39%'],
    category: '외장',
    element:
      '<path d="M129.564 21.5752C125.148 10.1193 122.891 9.19983 112.947 6.7371C111.978 6.49342 110.921 6.23351 109.763 5.9346C95.9818 2.38995 81.4198 0.693982 67.1504 0.820692C52.881 0.693982 38.319 2.38995 24.5374 5.9346C23.3797 6.23351 22.3228 6.49342 21.3538 6.7371C11.4094 9.19983 9.15262 10.1193 4.73652 21.5752C-1.19496 36.9689 0.346443 55.082 1.70899 71.0638C1.8098 72.2497 1.91061 73.4226 2.00492 74.589C2.15125 76.3532 3.97882 77.4578 5.61128 76.769C24.7813 68.679 45.9219 64.3514 66.7472 64.2474C66.8805 64.2474 67.0171 64.2474 67.1504 64.2474C67.2837 64.2474 67.4203 64.2474 67.5536 64.2474C88.3789 64.3514 109.52 68.679 128.69 76.769C130.322 77.4578 132.15 76.3532 132.296 74.589C132.39 73.4226 132.491 72.2497 132.592 71.0638C133.954 55.082 135.496 36.9689 129.564 21.5752Z" />',
  },
  {
    partPoint: 'ap_22',
    partNumber: '7',
    title: '루프패널',
    pos: ['50%', '48%'],
    reportPos: ['50%', '34%'],
    category: '외장',
    element:
      '<path d="M113.832 114.811L113.968 112.745L111.978 112.15C97.7834 107.917 82.5742 105.854 67.1504 105.964C51.7266 105.854 36.5174 107.917 22.3228 112.15L20.3327 112.745L20.4693 114.811C20.8595 120.757 21.3603 126.826 21.8448 132.7C23.7732 156.087 25.7666 180.272 21.5034 203.418L20.9896 206.199L23.8057 206.475C37.958 207.869 52.1949 208.567 66.4252 208.567C66.6659 208.567 66.9098 208.567 67.1504 208.564C67.391 208.567 67.6349 208.567 67.8756 208.567C82.1059 208.567 96.3428 207.869 110.495 206.475L113.311 206.199L112.797 203.418C108.534 180.272 110.528 156.087 112.456 132.7C112.941 126.826 113.441 120.757 113.832 114.811Z"/>',
  },
  {
    partPoint: 'ap_23',
    partNumber: '4',
    title: '트렁크리드',
    pos: ['83%', '48%'],
    reportPos: ['83%', '32%'],
    category: '외장',
    element:
      '<path d="M126.228 230.134C125.067 229.852 123.542 230.089 110.05 233.247C103.429 234.793 95.9265 236.548 93.3478 236.834C85.5172 237.698 77.3874 238.023 68.9032 237.802C67.7357 237.773 66.5651 237.773 65.3944 237.802C56.9101 238.023 48.7836 237.695 40.9498 236.834C38.371 236.548 30.8689 234.793 24.248 233.247C10.7558 230.089 9.23066 229.852 8.06973 230.134C6.46004 230.524 5.117 231.882 4.56092 233.679C4.44385 234.066 4.41458 234.468 4.47962 234.865C5.01944 238.163 4.96741 243.754 4.91213 249.677C4.82432 258.855 4.73327 268.346 6.81449 272.884C7.19497 273.706 7.90063 275.24 16.8141 278.567C29.825 283.437 48.1495 285.598 66.2008 285.598C66.5163 285.598 66.8317 285.598 67.1471 285.594C67.4626 285.598 67.778 285.598 68.0935 285.598C86.1448 285.598 104.469 283.437 117.48 278.567C126.394 275.24 127.099 273.706 127.48 272.884C129.561 268.346 129.47 258.855 129.382 249.677C129.327 243.754 129.275 238.163 129.815 234.865C129.88 234.468 129.85 234.066 129.733 233.682C129.177 231.882 127.834 230.524 126.225 230.134H126.228Z" />',
  },
  {
    partPoint: 'ap_24',
    partNumber: '20',
    title: '유리(전면)',
    pos: ['30%', '48%'],
    reportPos: ['30%', '32%'],
    category: '유리',
    element:
      '<path d="M17.5 115.5L4 79.2501C43 58 99 60.2499 132 79.2501L117.5 115.5C80.3 101.1 40 107.5 17.5 115.5Z"/>',
  },
  {
    partPoint: 'ap_25',
    partNumber: '20',
    title: '유리(후면)',
    pos: ['72%', '48%'],
    reportPos: ['72%', '32%'],
    category: '유리',
    element:
      '<path d="M119.5 205.5L127 231C89.5 243.5 46 244 9.5 229.25L16.5 205.5C45.5 210 92.5 210 119.5 205.5Z"/>',
  },
];

const ADD_PARTS_INNER = [
  {
    partPoint: 'ap_26',
    partNumber: '21',
    title: '핸들',
    pos: ['16%', '28%'],
    reportPos: ['16%', '10%'],
    category: '내장',
    element:
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M59.0845 75.0093C79.2221 75.0093 95.5469 58.2433 95.5469 37.5615C95.5469 16.8796 79.2221 0.113647 59.0845 0.113647C38.9469 0.113647 22.6222 16.8796 22.6222 37.5615C22.6222 58.2433 38.9469 75.0093 59.0845 75.0093ZM59.0845 66.14C74.3238 66.14 86.6777 53.345 86.6777 37.5614C86.6777 21.7779 74.3238 8.98283 59.0845 8.98283C43.8453 8.98283 31.4914 21.7779 31.4914 37.5614C31.4914 53.345 43.8453 66.14 59.0845 66.14Z"/>',
  },
  {
    partPoint: 'ap_27',
    partNumber: '22',
    title: '센터페시아',
    pos: ['25%', '46%'],
    reportPos: ['25%', '17%'],
    category: '내장',
    element: '<rect x="100.474" y="66.14" width="18.7239" height="39.4188"/>',
  },
  {
    partPoint: 'ap_28',
    partNumber: '23',
    title: '시트(운전석)',
    pos: ['33.75%', '31%'],
    reportPos: ['33.75%', '-28%'],
    category: '내장',
    element: '<rect x="47.2589" y="125.268" width="39.4188" height="36.4624"/>',
  },
  {
    partPoint: 'ap_29',
    partNumber: '23',
    title: '시트(조수석)',
    pos: ['33.75%', '62%'],
    reportPos: ['33.75%', '55%'],
    category: '내장',
    element: '<rect x="132.995" y="125.268" width="39.4188" height="36.4624"/>',
  },
  {
    partPoint: 'ap_30',
    partNumber: '23',
    title: '시트(뒷좌석)',
    pos: ['52.5%', '46%'],
    reportPos: ['64%', '14%'],
    category: '내장',
    element: '<rect x="47.2589" y="218.888" width="125.155" height="35.4769"/>',
  },
  {
    partPoint: 'ap_31',
    partNumber: '24',
    title: '등받이(운전석)',
    pos: ['40%', '31%'],
    reportPos: ['40%', '-32%'],
    category: '내장',
    element: '<rect x="47.2589" y="163.702" width="39.4188" height="11.8256"/>',
  },
  {
    partPoint: 'ap_32',
    partNumber: '24',
    title: '등받이(조수석)',
    pos: ['40%', '62%'],
    reportPos: ['40%', '55%'],
    category: '내장',
    element: '<rect x="132.995" y="163.702" width="39.4188" height="11.8256"/>',
  },
  {
    partPoint: 'ap_33',
    partNumber: '24',
    title: '등받이(뒷좌석)',
    pos: ['58.75%', '46%'],
    reportPos: ['69%', '14%'],
    category: '내장',
    element: '<rect x="47.2589" y="256.336" width="125.155" height="11.8256"/>',
  },
  {
    partPoint: 'ap_34',
    partNumber: '25',
    title: '헤드레스트(운전석)',
    pos: ['46.25%', '31%'],
    reportPos: ['46.25%', '-41%'],
    category: '내장',
    element: '<rect x="47.2589" y="185.382" width="39.4188" height="10.8402"/>',
  },
  {
    partPoint: 'ap_35',
    partNumber: '25',
    title: '헤드레스트(조수석)',
    pos: ['46.25%', '62%'],
    reportPos: ['46.25%', '55%'],
    category: '내장',
    element: '<rect x="132.995" y="185.382" width="39.4188" height="10.8402"/>',
  },
  {
    partPoint: 'ap_36',
    partNumber: '25',
    title: '헤드레스트(뒷좌석)',
    pos: ['65%', '46%'],
    reportPos: ['76%', '8%'],
    category: '내장',
    element: '<rect x="47.2589" y="278.016" width="125.155" height="10.8402"/>',
  },
  {
    partPoint: 'ap_37',
    partNumber: '5',
    title: '트렁크',
    pos: ['82.5%', '46%'],
    reportPos: ['82.5%', '19%'],
    category: '내장',
    element:
      '<path opacity="0.6" d="M0.5 377L6 326.5C54.4 344.5 165.5 334 215 326.5V377L201 438.5C126.2 464.1 48.5 449.167 17.5 438.5L0.5 377Z"/>',
  },
  {
    partPoint: 'ap_38',
    partNumber: '27',
    title: '도어트림(운전석앞)',
    pos: ['37.5%', '5%'],
    reportPos: ['53%', '-40%'],
    category: '내장',
    element: '<rect x="4" y="134" width="19" height="62"/>',
  },
  {
    partPoint: 'ap_39',
    partNumber: '27',
    title: '도어트림(운전석뒤)',
    pos: ['55%', '5%'],
    reportPos: ['60%', '-40%'],
    category: '내장',
    element: '<rect x="4" y="219" width="19" height="62"/>',
  },
  {
    partPoint: 'ap_40',
    partNumber: '27',
    title: '도어트림(조수석앞)',
    pos: ['37.5%', '87%'],
    reportPos: ['53%', '55%'],
    category: '내장',
    element: '<rect x="196" y="134" width="19" height="62"/>',
  },
  {
    partPoint: 'ap_41',
    partNumber: '27',
    title: '도어트림(조수석뒤)',
    pos: ['55%', '87%'],
    reportPos: ['60%', '55%'],
    category: '내장',
    element: '<rect x="196" y="219" width="19" height="62"/>',
  },
  {
    partPoint: 'ap_42',
    partNumber: '28',
    title: '팔걸이(운전석좌)',
    pos: ['37.5%', '19.5%'],
    reportPos: ['21%', '-37%'],
    category: '내장',
    element: '<rect x="30.506" y="115.414" width="13.7966" height="67.0119"/>',
  },
  {
    partPoint: 'ap_43',
    partNumber: '28',
    title: '팔걸이(운전석우)',
    pos: ['37.5%', '41%'],
    reportPos: ['28%', '-37%'],
    category: '내장',
    element: '<rect x="88.6486" y="115.414" width="13.7966" height="67.0119"/>',
  },
  {
    partPoint: 'ap_44',
    partNumber: '28',
    title: '팔걸이(조수석좌)',
    pos: ['37.5%', '51%'],
    reportPos: ['21%', '55%'],
    category: '내장',
    element: '<rect x="116.242" y="115.414" width="13.7966" height="67.0119"/>',
  },
  {
    partPoint: 'ap_45',
    partNumber: '28',
    title: '팔걸이(조수석우)',
    pos: ['37.5%', '73%'],
    reportPos: ['28%', '55%'],
    category: '내장',
    element: '<rect x="174.384" y="115.414" width="13.7966" height="67.0119"/>',
  },
  {
    partPoint: 'ap_46',
    partNumber: '28',
    title: '팔걸이(뒷좌석좌)',
    pos: ['53%', '19.5%'],
    reportPos: ['69%', '-37%'],
    category: '내장',
    element: '<rect x="30.506" y="208.048" width="13.7966" height="67.0119"/>',
  },
  {
    partPoint: 'ap_47',
    partNumber: '28',
    title: '팔걸이(뒷좌석우)',
    pos: ['53%', '73%'],
    reportPos: ['69%', '55%'],
    category: '내장',
    element: '<rect x="174.384" y="208.048" width="13.7966" height="67.0119"/>',
  },
];

const ADD_PARTS_LEFT_WHEEL = [
  {
    partPoint: 'carWheelFL',
    partNumber: '13',
    title: '휠(좌측전방)',
    pos: [0, 0],
    reportPos: [45, -40],
    category: '휠',
    element:
      "<path class='st0' d='M35.9,45.8c-8,0-14.4,6.5-14.4,14.4s6.5,14.4,14.4,14.4,14.4-6.5,14.4-14.4-6.5-14.4-14.4-14.4ZM32.7,61.3l-7.5,5.3c-.9-1.6-1.5-3.4-1.7-5.3h9.2ZM23.5,59.3c.2-2,.8-3.8,1.7-5.4l7.5,5.4h-9.3ZM39.1,59.3l7.5-5.3c.9,1.6,1.5,3.4,1.7,5.3h-9.2ZM48.3,61.3c-.2,2-.8,3.8-1.7,5.4l-7.5-5.4h9.3ZM45.5,52.3l-8.6,6v-10.4c3.4.3,6.5,1.9,8.6,4.4ZM34.9,47.9v10.4l-8.5-6.1c2.1-2.4,5.1-4.1,8.5-4.4ZM26.4,68.2l8.6-6v10.4c-3.4-.3-6.5-1.9-8.6-4.4ZM36.9,72.6v-10.4l8.5,6.1c-2.1,2.4-5.1,4.1-8.5,4.4Z'/>",
  },
  {
    partPoint: 'carWheelRL',
    partNumber: '13',
    title: '휠(좌측후방)',
    pos: [0, 0],
    reportPos: [180, -40],
    category: '휠',
    element:
      "<path class='st1' d='M35.9,196.1c-8,0-14.4,6.5-14.4,14.4s6.5,14.4,14.4,14.4,14.4-6.5,14.4-14.4-6.5-14.4-14.4-14.4ZM32.7,211.6l-7.5,5.3c-.9-1.6-1.5-3.4-1.7-5.3h9.2ZM23.5,209.6c.2-2,.8-3.8,1.7-5.4l7.5,5.4h-9.3ZM39.1,209.6l7.5-5.3c.9,1.6,1.5,3.4,1.7,5.3h-9.2ZM48.3,211.6c-.2,2-.8,3.8-1.7,5.4l-7.5-5.4h9.3ZM45.5,202.6l-8.6,6v-10.4c3.4.3,6.5,1.9,8.6,4.4ZM34.9,198.2v10.4l-8.5-6.1c2.1-2.4,5.1-4.1,8.5-4.4ZM26.4,218.5l8.6-6v10.4c-3.4-.3-6.5-1.9-8.6-4.4ZM36.9,222.9v-10.4l8.5,6.1c-2.1,2.4-5.1,4.1-8.5,4.4Z'/>",
  },
  {
    partPoint: 'tiretreadFL',
    partNumber: '99',
    title: '타이어트레드(좌측전방)',
    pos: [0, 0],
    reportPos: [65, -85],
    category: '휠',
    element:
      "<path class='st0' d='M35.9,74.7c-8,0-14.4-6.5-14.4-14.4s6.5-14.4,14.4-14.4,14.4,6.5,14.4,14.4-6.5,14.4-14.4,14.4ZM35.9,47.8c-6.8,0-12.4,5.6-12.4,12.4s5.6,12.4,12.4,12.4,12.4-5.6,12.4-12.4-5.6-12.4-12.4-12.4Z'/><path class='st0' d='M35.9,80.5c-11.2,0-20.3-9.1-20.3-20.3s9.1-20.3,20.3-20.3,20.3,9.1,20.3,20.3-9.1,20.3-20.3,20.3ZM35.9,42c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3,18.3-8.2,18.3-18.3-8.2-18.3-18.3-18.3Z'/>",
  },
  {
    partPoint: 'tiretreadRL',
    partNumber: '99',
    title: '타이어트레드(좌측후방)',
    pos: [0, 0],
    reportPos: [200, -85],
    category: '휠',
    element:
      "<path class='st0' d='M35.9,225c-8,0-14.4-6.5-14.4-14.4s6.5-14.4,14.4-14.4,14.4,6.5,14.4,14.4-6.5,14.4-14.4,14.4ZM35.9,198.1c-6.8,0-12.4,5.6-12.4,12.4s5.6,12.4,12.4,12.4,12.4-5.6,12.4-12.4-5.6-12.4-12.4-12.4Z'/><path class='st0' d='M35.9,230.8c-11.2,0-20.3-9.1-20.3-20.3s9.1-20.3,20.3-20.3,20.3,9.1,20.3,20.3-9.1,20.3-20.3,20.3ZM35.9,192.3c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3,18.3-8.2,18.3-18.3-8.2-18.3-18.3-18.3Z'/>",
  },
];

const ADD_PARTS_RIGHT_WHEEL = [
  {
    partPoint: 'carWheelFR',
    partNumber: '13',
    title: '휠(우측전방)',
    pos: [0, 0],
    reportPos: [45, 130],
    category: '휠',
    element:
      "<path class='st0' d='M35.9,45.8c-8,0-14.4,6.5-14.4,14.4s6.5,14.4,14.4,14.4,14.4-6.5,14.4-14.4-6.5-14.4-14.4-14.4ZM32.7,61.3l-7.5,5.3c-.9-1.6-1.5-3.4-1.7-5.3h9.2ZM23.5,59.3c.2-2,.8-3.8,1.7-5.4l7.5,5.4h-9.3ZM39.1,59.3l7.5-5.3c.9,1.6,1.5,3.4,1.7,5.3h-9.2ZM48.3,61.3c-.2,2-.8,3.8-1.7,5.4l-7.5-5.4h9.3ZM45.5,52.3l-8.6,6v-10.4c3.4.3,6.5,1.9,8.6,4.4ZM34.9,47.9v10.4l-8.5-6.1c2.1-2.4,5.1-4.1,8.5-4.4ZM26.4,68.2l8.6-6v10.4c-3.4-.3-6.5-1.9-8.6-4.4ZM36.9,72.6v-10.4l8.5,6.1c-2.1,2.4-5.1,4.1-8.5,4.4Z'/>",
  },
  {
    partPoint: 'carWheelRR',
    partNumber: '13',
    title: '휠(우측후방)',
    pos: [0, 0],
    reportPos: [180, 130],
    category: '휠',
    element:
      "<path class='st1' d='M35.9,196.1c-8,0-14.4,6.5-14.4,14.4s6.5,14.4,14.4,14.4,14.4-6.5,14.4-14.4-6.5-14.4-14.4-14.4ZM32.7,211.6l-7.5,5.3c-.9-1.6-1.5-3.4-1.7-5.3h9.2ZM23.5,209.6c.2-2,.8-3.8,1.7-5.4l7.5,5.4h-9.3ZM39.1,209.6l7.5-5.3c.9,1.6,1.5,3.4,1.7,5.3h-9.2ZM48.3,211.6c-.2,2-.8,3.8-1.7,5.4l-7.5-5.4h9.3ZM45.5,202.6l-8.6,6v-10.4c3.4.3,6.5,1.9,8.6,4.4ZM34.9,198.2v10.4l-8.5-6.1c2.1-2.4,5.1-4.1,8.5-4.4ZM26.4,218.5l8.6-6v10.4c-3.4-.3-6.5-1.9-8.6-4.4ZM36.9,222.9v-10.4l8.5,6.1c-2.1,2.4-5.1,4.1-8.5,4.4Z'/>",
  },
  {
    partPoint: 'tiretreadFR',
    partNumber: '99',
    title: '타이어트레드(우측전방)',
    pos: [0, 0],
    reportPos: [65, 130],
    category: '휠',
    element:
      "<path class='st0' d='M35.9,74.7c-8,0-14.4-6.5-14.4-14.4s6.5-14.4,14.4-14.4,14.4,6.5,14.4,14.4-6.5,14.4-14.4,14.4ZM35.9,47.8c-6.8,0-12.4,5.6-12.4,12.4s5.6,12.4,12.4,12.4,12.4-5.6,12.4-12.4-5.6-12.4-12.4-12.4Z'/><path class='st0' d='M35.9,80.5c-11.2,0-20.3-9.1-20.3-20.3s9.1-20.3,20.3-20.3,20.3,9.1,20.3,20.3-9.1,20.3-20.3,20.3ZM35.9,42c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3,18.3-8.2,18.3-18.3-8.2-18.3-18.3-18.3Z'/>",
  },
  {
    partPoint: 'tiretreadRR',
    partNumber: '99',
    title: '타이어트레드(우측후방)',
    pos: [0, 0],
    reportPos: [200, 130],
    category: '휠',
    element:
      "<path class='st0' d='M35.9,225c-8,0-14.4-6.5-14.4-14.4s6.5-14.4,14.4-14.4,14.4,6.5,14.4,14.4-6.5,14.4-14.4,14.4ZM35.9,198.1c-6.8,0-12.4,5.6-12.4,12.4s5.6,12.4,12.4,12.4,12.4-5.6,12.4-12.4-5.6-12.4-12.4-12.4Z'/><path class='st0' d='M35.9,230.8c-11.2,0-20.3-9.1-20.3-20.3s9.1-20.3,20.3-20.3,20.3,9.1,20.3,20.3-9.1,20.3-20.3,20.3ZM35.9,192.3c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3,18.3-8.2,18.3-18.3-8.2-18.3-18.3-18.3Z'/>",
  },
];

const PARTS_ALL = _.concat(PARTS_LEFT, PARTS_RIGHT, PARTS_TOP, PARTS_BOTTOM);

const ADD_PARTS_STATUS = [
  {
    category: '외장',
    status_code: 'T',
    status_name: '손상',
    severity: 'secondary',
    color: '#607D8B',
  },
  {
    category: '외장',
    status_code: 'G',
    status_name: '광택',
    severity: 'contrast',
    color: '#2296F3',
  },
  {
    category: '유리',
    status_code: 'R',
    status_name: '수리',
    severity: 'success',
    color: '#689F39',
  },
  {
    category: '유리',
    status_code: 'E',
    status_name: '교환',
    severity: 'warning',
    color: '#FBC02D',
  },
  {
    category: '내장',
    status_code: 'X',
    status_name: '불량',
    severity: 'danger',
    color: '#D32F2F',
  },
  {
    category: '휠',
    status_code: 5,
    status_name: '불량',
    severity: '',
    color: '#ff00d9',
  },
  {
    category: '타이어트레드',
    status_code: 0,
    status_name: '불량',
    severity: '',
    color: '#ff00d9',
  },
];

const ACCIDENT_STATUS = {
  pp_162: ['2'], // 6.쿼터패널(좌)
  pp_179: ['2'], // 6.쿼터패널(우)
  pp_107: ['2'], // 7.루프패널
  pp_81: ['2'], // 8.사이드실패널(우)
  pp_100: ['2'], // 8.사이드실패널(좌)
  pp_33: ['2', '3'], // 9.프론트패널
  // pp_73: ['2', '3'], // 10.크로스멤버
  pp_32: ['2', '3'], // 11.인사이드패널(좌)
  pp_34: ['2', '3'], // 11.인사이드패널(우)
  pp_53: ['2', '3'], // 12.사이드멤버(좌전)
  pp_54: ['2', '3'], // 12.사이드멤버(우전)
  pp_172: ['2', '3'], // 12.사이드멤버(좌후)
  pp_174: ['2', '3'], // 12.사이드멤버(우후)
  pp_52: ['2', '3'], // 13.휠하우스(좌전)
  pp_55: ['2', '3'], // 13.휠하우스(우전)
  pp_152: ['2', '3'], // 13.휠하우스(좌후)
  pp_154: ['2', '3'], // 13.휠하우스(우후)
  pp_63: ['2', '3'], // 14.A필러(좌)
  pp_103: ['2', '3'], // 14.B필러(좌)
  pp_144: ['2', '3'], // 14.C필러(좌)
  pp_78: ['2', '3'], // 14.A필러(우)
  pp_118: ['2', '3'], // 14.B필러(우)
  pp_137: ['2', '3'], // 14.C필러(우)
  pp_74: ['2', '3'], // 15.대쉬패널
  pp_94: ['2', '3'], // 16.플로어패널
  pp_173: ['2', '3'], // 17.트렁크플로어
  pp_194: ['2', '3'], // 18.리어패널
  pp_153: ['2', '3'], // 19.패키지트레이
};

const REPAIR_STATUS = {
  pp_28: ['2', '3'], // 1.후드
  pp_22: ['2', '3'], // 2.프론트휀더(좌)
  pp_39: ['2', '3'], // 2.프론트휀더(우)
  pp_82: ['2', '3'], // 3.도어(좌측전방)
  pp_102: ['2', '3'], // 3.도어(좌측후방)
  pp_99: ['2', '3'], // 3.도어(우측전방)
  pp_139: ['2', '3'], // 3.도어(우측후방)
  pp_168: ['2', '3'], // 4.트렁크리드
  pp_14: ['2', '3'], // 5.라디에이터서포트
  pp_162: ['3'], // 6.쿼터패널(좌)
  pp_179: ['3'], // 6.쿼터패널(우)
  pp_107: ['3'], // 7.루프패널
  pp_81: ['3'], // 8.사이드실패널(좌)
  pp_100: ['3'], // 8.사이드실패널(우)
  pp_73: ['2'], // 10.크로스멤버
};

const MAIN_FRAMES = [
  { key: '1', dataKey: 's037' }, // 1.후드
  { key: '2', dataKey: 's038' }, // 2.프론트휀더
  { key: '3', dataKey: 's039' }, // 3.도어
  { key: '4', dataKey: 's040' }, // 4.트렁크 리드
  { key: '5', dataKey: 's041' }, // 5.라디에이터서포트(볼트체결부품)
  { key: '6', dataKey: 's043' }, // 6.쿼터패널
  { key: '7', dataKey: 's042' }, // 7.루프패널
  { key: '8', dataKey: 's044' }, // 8.사이드실패널
  { key: '9', dataKey: 's045' }, // 9.프론트패널
  { key: '10', dataKey: 's046' }, // 10.크로스멤버
  { key: '11', dataKey: 's047' }, // 11.인사이드패널
  { key: '12', dataKey: 's048' }, // 12.사이드멤버
  { key: '13', dataKey: 's049' }, // 13.휠하우스
  { key: '14', dataKey: 's052' }, // 14.필러패널
  { key: '14A', dataKey: 'D14A' }, // 14.필러패널 A
  { key: '14B', dataKey: 'D14B' }, // 14.필러패널 B
  { key: '14C', dataKey: 'D14C' }, // 14.필러패널 C
  { key: '15', dataKey: 's050' }, // 15.대쉬패널
  { key: '16', dataKey: 's051' }, // 16.플로워패널
  { key: '17', dataKey: 's054' }, // 17.트렁크플로워
  { key: '18', dataKey: 's053' }, // 18.리어패널
  { key: '19', dataKey: 's056' }, // 19.패키지트레이
];

const GASOLINE_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's035',
  's036',
];

const DIESEL_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's011',
  's016',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's035',
];

const LPG_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's016',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's035',
];

const ELECTRONIC_PARTS = [
  's025',
  's061',
  's027',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
];

const HYDROGEN_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
  's035',
];

const HYBRID_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
  's035',
];

const ETC_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's021',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
  's035',
];

const PHOTO_TYPE = {
  OUT_FRONT: '정면',
  OUT_REAR: '후면',
  SCAN: '성능점검 스캔본',
  ETC: '',
  FIXED: '정비',
};

export {
  PC_VALID_DAYS,
  PC_BASE_BLUEPRINT,
  PC_STATUS_BLUEPRINT,
  PC_ADD_BLUEPRINT,
  PARTS_LEFT,
  PARTS_RIGHT,
  PARTS_TOP,
  PARTS_BOTTOM,
  ADD_PARTS_LEFT,
  ADD_PARTS_RIGHT,
  ADD_PARTS_TOP,
  ADD_PARTS_INNER,
  ADD_PARTS_LEFT_WHEEL,
  ADD_PARTS_RIGHT_WHEEL,
  PARTS_ALL,
  ACCIDENT_STATUS,
  ADD_PARTS_STATUS,
  REPAIR_STATUS,
  MAIN_FRAMES,
  GASOLINE_PARTS,
  DIESEL_PARTS,
  LPG_PARTS,
  ELECTRONIC_PARTS,
  HYDROGEN_PARTS,
  HYBRID_PARTS,
  ETC_PARTS,
  PHOTO_TYPE,
};
